import "./styles.scss";

import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

const Footer = () => {
    
    return (
        <StaticQuery
          query={graphql`
              {
                allWp {
                  nodes {
                    id
                    optionsPage {
                      global {
                        footerEmail
                        footerName
                        footerPosition
                        footerTel
                        footerFb
                        footerInsta
                        footerYt
                      }
                    }
                  }
                }
              }
          `}
          render={data => (
            
            <footer>
            <div className="container-fluid">
              <div className="site-container">
                <div className="footer">
                  <div className="footer__col-1">
                    <div className="footer__col-1--wrapper">
                      <Link to="/">
                        <img
                          className="footer__logo"
                          src={require("assets/svg/logo_footer.svg").default}
                          alt=""
                          />
                      </Link>
                      <div className="footer__col-1__content">
                        <p className="footer__text">
                          <span className="footer__text--bold">&copy; {new Date().getFullYear()} Maciej Uchmański</span><br></br>
                          Wszelkie prawa zastrzeżone
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="footer__col-2">
                    <div className="footer__col-2__content">
                        <p className="footer__text">
                          <span className="footer__text--bold">{data?.allWp.nodes[0]?.optionsPage?.global?.footerPosition}</span><br></br>
                          {data?.allWp.nodes[0]?.optionsPage?.global?.footerName}<br></br>
                          <a className="footer__text" href={`tel:${data?.allWp.nodes[0]?.optionsPage?.global?.footerTel}`}>{data?.allWp.nodes[0]?.optionsPage?.global?.footerTel}</a><br></br>
                          <a className="footer__text" href={`mailto:${data?.allWp.nodes[0]?.optionsPage?.global?.footerEmail}`}>{data?.allWp.nodes[0]?.optionsPage?.global?.footerEmail}</a><br></br>
                        </p>
                      </div>
                  </div>
                  <div className="footer__col-3">
                    <div className="footer__col-3__content">
                      <p className="footer__text">
                        <span className="footer__text--bold">Chcesz stworzyć coś Wielkiego?</span><br></br>
                        Wyślij zapytanie przez formularz
                      </p>
                      <Link className="btn-main" to="/kontakt/">Kliknij tutaj</Link>
                    </div>
                  </div>
                  <div className="footer__col-4">
                    <a target="_blank" href={data?.allWp.nodes[0]?.optionsPage?.global?.footerFb}>
                      <img
                          className="footer__icon"
                          src={require("assets/svg/fb.svg").default}
                          alt=""
                          />
                    </a>
                    <a target="_blank" href={data?.allWp.nodes[0]?.optionsPage?.global?.footerYt}>
                      <img
                          className="footer__icon"
                          src={require("assets/svg/yt.svg").default}
                          alt=""
                          />
                    </a>
                    <a target="_blank" href={data?.allWp.nodes[0]?.optionsPage?.global?.footerInsta}>
                      <img
                          className="footer__icon"
                          src={require("assets/svg/insta.svg").default}
                          alt=""
                          />
                    </a>
                  </div>  
                </div>
                <div className="row footer__policy">
                  <div className="col-12 footer__policy__col">
                    <div className="footer__policy__wrapper">
                      <Link to="/polityka-cookies/">Polityka cookies</Link>  
                      <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
           )}
        />
  );
};

export default Footer;