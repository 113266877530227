import "./styles.scss";

import React, { useState } from "react";
import { Link } from "gatsby";

const Header = ( {header_setup, isNavbarHidden} ) => {

  const {logo, fontColor, navbarColor} = header_setup;

  const [menuOpen, setMenuOpen] = useState(false);

  const items = [
    { name: 'Strona główna', href: '/' },
    { name: 'Oferta', href: '/oferta/' },
    { name: 'Portfolio', href: '/portfolio/' },
    { name: 'Klienci', href: '/klienci/' },
    { name: 'O mnie', href: '/o-mnie/' },
    { name: 'Kontakt', href: '/kontakt/' }
  ]
  
  return (
    <header style={{background: navbarColor}} className={isNavbarHidden ? "hidden" : ""}>
      <div className="container-fluid">
        <div className="site-container">
          <div className="header">
            <div className="header__logo">
              <Link to="/">
                <img
                  className="header__logo__img"
                  src={require(`assets/svg/${logo}`).default}
                  alt=""
                  />
              </Link>
            </div>
            <nav className={`header__nav${menuOpen ? " mobile-menu" : ""}`} style={{color: fontColor}}>
              {items.map(({ name, href }) => <Link activeClassName="active" key={name} data-background={name} className="header__nav__item" to={href}><span>{name}</span>
              </Link>
              )}
            </nav>
            <button className={`hamburger d-lg-none${
          menuOpen ? " menu-open" : "" }`} onClick={() => setMenuOpen(!menuOpen)}>
              <span style={{background: fontColor}}></span>
              <span style={{background: fontColor}}></span>
              <span style={{background: fontColor}}></span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;