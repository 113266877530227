import "./styles.scss";

import React, { useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import { StaticQuery, graphql } from "gatsby";

const CookieBanner = ({ cookies }) => {

  const [cookieBanner, setCookieBanner] = useState("");

  useEffect(() => {
    const isAcceptedCoookie = !!cookies.get("cookie-accept-uchmanski")
    !isAcceptedCoookie && setCookieBanner(true)
  }, [cookies]);

  const acceptCookieBanner = e => {
    e.preventDefault()
    const promiseSetCookie = new Promise(resolve =>
      resolve(
        cookies.set("cookie-accept-uchmanski", "active", { path: "/" })
      )
    )
    promiseSetCookie.then(() => {
      setCookieBanner(false)
    })
  }

    return (
      <StaticQuery
          query={graphql`
          {
            allWp {
              nodes {
                id
                optionsPage {
                  global {
                    cookiesHeading
                    cookiesText
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className={`cookie-banner${cookieBanner ? " cookie-banner--open" : ""}`}>
            <div className="site-container">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                      <div className="cookie-banner__content">
                          <div className="cookie-banner__info">
                            <h3>{data?.allWp.nodes[0]?.optionsPage?.global?.cookiesHeading}</h3>
                            <div dangerouslySetInnerHTML={{ __html: data?.allWp.nodes[0]?.optionsPage?.global?.cookiesText }} />
                          </div>
                          <div className="cookie-banner__buttons">
                            <button className="btn-main" onClick={(e) => acceptCookieBanner(e)}>Wyrażam zgodę</button>
                            <button className="btn-main btn-main--beige" onClick={() => setCookieBanner(false)}>Nie wyrażam zgody</button>
                          </div>
                      </div>
                  </div>
                </div>  
              </div>  
            </div>
          </div>
        )}
      />
  );
};

export default withCookies(CookieBanner);