import React from "react";
import { CookiesProvider } from 'react-cookie';

import Header from "./components/Header";
import Footer from "./components/Footer";
import Seo from "./components/Seo";
import CookieBanner from "../CookieBanner";

const Layout = ( { seo={}, header_setup, isNavbarHidden=false, children } ) => {
  
  return (
    <CookiesProvider>
      <Header header_setup={header_setup} isNavbarHidden={isNavbarHidden} />
      <Seo {...seo} />
      <main>{children}</main>
      <Footer />
      <CookieBanner />
    </CookiesProvider>   
  );
};

export default Layout;
